<template>
    <v-text-field
        v-model="field.value"
        :label="i18n(field, 'label')"
        filled
        validate-on="blur"
        dense
        :required="field.required"
    ></v-text-field>
</template>

<script>

    export default {
        name: 'field-text',
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
