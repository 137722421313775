import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FormsView from '../views/FormsView.vue'
// import agrariaForm from '../json/agraria-form.json';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    path: '/evenimente',
    name: 'evenimente',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EvenimenteView.vue')
  }, {
    path: '/eveniment/:slugEvent/:slugFormular',
    name: 'formular2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompleteazaFormularView.vue')
  }, {
    path: '/utilizatori',
    name: 'utilizatori',
    component: () => import(/* webpackChunkName: "about" */ '../views/UtilizatoriView.vue')
  }, {
    path: '/expozant/:slugExpozant',
    name: 'expozant',
    component: () => import(/* webpackChunkName: "about" */ '../views/ExpozantView.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-1',
    name: 'formular',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularView.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-3-6',
    name: 'formular-3-6',
    component: () => import(/* webpackChunkName: "about" */ '../views/Formular36View.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-agriplanta-f1',
    name: 'formular-agriplanta-f1',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularAgriplantaF1.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-agriplanta-f3',
    name: 'formular-agriplanta-f3',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularAgriplantaF3.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-agriplanta-f4',
    name: 'formular-agriplanta-f4',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularAgriplantaF4.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-agriplanta-f1_f3-f6',
    name: 'formular-agriplanta-f1_f3-f6',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularAgriplantaF1_F3-F6.vue')
  }, {
    path: '/expozant/:slugExpozant/formular-agriplanta-2024',
    name: 'formular-agriplanta-2024',
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularAgriplanta2024.vue')
  }, {
    path: '/formulare',
    name: 'formulare',
    component: FormsView,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
