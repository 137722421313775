<template>
    <v-radio-group
        v-model="field.value"
        required
    >
        <template v-slot:label>
            <div class="subtitle-1 font-weight-black">{{i18n(field, 'label')}}</div>
        </template>
        <v-radio
            v-for="item in field.items"
            :key="item.value"
            :label="i18n(item, 'label')"
            :value="item.value"
        >
            <template v-slot:label>
                <div>
                    <div class="font-weight-medium">{{i18n(item, 'label')}}</div>
                    <div class="font-weight-regular caption">{{i18n(item, 'hint')}}</div>
                </div>
            </template>
        </v-radio>
    </v-radio-group>
</template>

<script>

    export default {
        name: 'field-radio',
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
