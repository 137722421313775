<template>
    <p class="text-body-2">{{i18n(field, 'value')}}</p>
</template>

<script>

    export default {
        name: 'field-paragraph',
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
