<template>
    <field-group :form="form" :field="field" :section="section"></field-group>
</template>

<script>
    import FieldGroup from './FieldGroup.vue'

    export default {
        name: 'field-fieldgroup',
        components: {
            FieldGroup
        },
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            },
            section: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
        },
        methods: {
        }
    }
</script>