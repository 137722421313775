<template>
    <div>
        <div class="subtitle-1 font-weight-black">{{i18n(field, 'label')}}</div>
        <div class="mb-6">
            <v-checkbox
                v-model="field.value"
                v-for="item in field.items"
                :key="item.value"
                :value="item.value"
                :messages="item.hint"
                :id="item.value"
                :hide-details="!!!item.hint"
                multiple
            >
                <template v-slot:label>
                    <div>
                        <div class="font-weight-medium">{{i18n(item, 'label')}}</div>
                        <v-chip
                            v-if="item.price"
                            class="mt-1"
                            color="primary"
                            small
                        >
                            + {{item.price}} EURO
                        </v-chip>
                    </div>
                </template>
            </v-checkbox>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'field-checkbox',
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            },
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
