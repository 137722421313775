<template>
  <div>
    <div class="subtitle-2">
      {{ i18n(field, "label") }}
      <v-chip class="ma-2" color="primary">
        <span v-if="field.checkboxValues">
          {{ checkboxCalc(field).toFixed(2) }} {{ i18n(field, "suffix") }}
        </span>
        <span v-else-if="field.calc">
          {{ calc(field, field.calc).toFixed(2) }} {{ i18n(field, "suffix") }}
        </span>
        <span v-else-if="field.inheritValue">
          {{ inheritValue(field).toFixed(2) }} {{ i18n(field, "suffix") }}
        </span>
        <span v-else-if="field.variableValue">
          {{ variableValue(field).toFixed(2) }} {{ i18n(field, "suffix") }}
        </span>
        <span v-else>
          {{ field.value.toFixed(2) }}{{ i18n(field, "suffix") }}
        </span>
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "field-text",
  props: {
    field: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  mounted() {},
  methods: {
    checkboxCalc(field) {
      const targetField = this.searchObjectById(
        field.checkboxValues,
        this.form.sections
      );
      if (targetField.value) {
        let output = 0;
        targetField.value.forEach((val) => {
          output += targetField.items.find((item) => item.value === val).price;
        });
        field.value = output;
        return output;
      } else {
        return 0;
      }
    },
    i18n(obj, key) {
      const language = this.form.data.language;
      return obj.i18n?.[language]?.[key] || obj[key];
    },
    calc(field, calc) {
      const operatorMap = {
        "*": "*",
        "+": "+",
        "-": "-",
        "(": "(",
        ")": ")",
      };

      const arr = [];
      for (const key in calc) {
        const operator = operatorMap[calc[key]];
        if (operator) {
          arr.push(operator);
        } else {
          if (typeof calc[key] === "number") {
            arr.push(calc[key]);
          } else {
            const op = this.searchObjectById(calc[key], this.section);
            if (typeof op !== "undefined") {
              if (op.active) {
                arr.push(Number(op.value));
              } else {
                arr.push(
                  arr[arr.length - 1] === "*" || arr[arr.length - 1] === "/"
                    ? 1
                    : 0
                );
              }
            } else {
              console.error("calc", calc[key]);
            }
          }
        }
      }

      const result = eval(arr.join(" "));
      field.value = result;
      return result;
    },
    variableValue(field) {
      if (!field.variableValue) {
        return;
      }
      const ifs = field.variableValue.if;
      const operator = {
        isBetween: (a, b, c) => a >= b[0] && a <= b[1],
        isGreater: (a, b) => a > b,
      };

      for (const index in ifs) {
        const a = this.searchObjectById(ifs[index].condition[0], this.section)
          .value;
        const opName = ifs[index].condition[1];
        const b = ifs[index].condition[2];

        const result = operator[opName](a, b);
        if (result) {
          field.value = ifs[index].value;
          return ifs[index].value;
        }
      }
      field.value = field.variableValue.else;
      return field.variableValue.else;
    },
    searchObjectById(id, obj) {
      if (obj.id === id) {
        return obj;
      }
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          const result = this.searchObjectById(id, obj[key]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    inheritValue(field) {
      console.log(field);
      let arr = [];
      for (const key in field.inheritValue) {
        arr = [];
        for (const k in field.inheritValue[key].if) {
          arr.push(
            field.inheritValue[key].if[k].value ===
              this.searchObjectById(field.inheritValue[key].if[k].id, this.form)
                .value
          );
        }
        const is = arr.every((a) => a);
        if (is) {
          field.value = field.inheritValue[key].value;
          return field.value;
        }
      }
    },
  },
};
</script>
