<template>
  <div>
    <!-- <code>{{ field.id }}</code> -->
    <component
      v-if="
        field.conditions && field.conditions.mode === 'show'
          ? conditions(field, field.conditions)
          : true
      "
      :key="field.id"
      :name="'field-' + field.type"
      :is="'field-' + field.type"
      :field="field"
      :section="section"
      :form="form"
    ></component>
  </div>
</template>

<script>
import { required, email, regex } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode } from "vee-validate";
import FieldGroup from "./FieldGroup.vue";
import FieldText from "./FieldText.vue";
import FieldMail from "./FieldMail.vue";
import FieldCheckbox from "./FieldCheckbox.vue";
import FieldRadio from "./FieldRadio.vue";
import FieldCombobox from "./FieldCombobox.vue";
import FieldPhone from "./FieldPhone.vue";
import FieldPrice from "./FieldPrice.vue";
import FieldSelect from "./FieldSelect.vue";
import FieldNumber from "./FieldNumber.vue";
import FieldParagraph from "./FieldParagraph.vue";
import FieldFieldgroup from "./FieldFieldgroup.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "required",
});

extend("email", {
  ...email,
  message: "email",
});

extend("regex", {
  ...regex,
  message: "phone",
});

extend("phone", {
  validate: (value) => {
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return phoneRegex.test(value);
  },
  message: "phone",
});

export default {
  name: "field-types",
  components: {
    ValidationProvider,
    FieldGroup,
    FieldText,
    FieldCheckbox,
    FieldCombobox,
    FieldMail,
    FieldRadio,
    FieldPrice,
    FieldSelect,
    FieldNumber,
    FieldParagraph,
    FieldPhone,
    FieldFieldgroup,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    items: [
      {
        header: "Selecteaza o marca sau creaza una",
      },
      {
        text: "NHR AGROPARTNERS",
        color: "blue",
      },
      {
        text: "CARGILL FURAJ SI NUTRITIE",
        color: "red",
      },
    ],
    categoriiExpozant: [
      {
        header: "Selecteaza o categorie sau creaza una",
      },
      {
        text: "Producător",
        color: "blue",
      },
      {
        text: "Importator",
        color: "red",
      },
      {
        text: "Distribuitor",
        color: "red",
      },
      {
        text: "Prestări servicii",
        color: "red",
      },
      {
        text: "Asociaţie",
        color: "red",
      },
    ],
  }),
  mounted() {},
  methods: {
    findObjectById(id, object) {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          if (object[key] && typeof object[key] === "object") {
            const result = this.findObjectById(id, object[key]);
            if (result) {
              return result;
            }
          }
          if (object[key] && object[key].id === id) {
            return object[key];
          }
        }
      }
    },
    calc(field, calc) {
      const arr = [];
      for (const key in calc) {
        if (calc[key] === "*") {
          arr.push("*");
        } else if (calc[key] === "+") {
          arr.push("+");
        } else if (calc[key] === "-") {
          arr.push("-");
        } else if (calc[key] === "(") {
          arr.push("(");
        } else if (calc[key] === ")") {
          arr.push(")");
        } else {
          if (typeof calc[key] == "number") {
            arr.push(calc[key]);
          } else {
            const obj = this.group || this.form;
            const op = this.findObjectById(calc[key], obj);
            if (typeof op !== "undefined") {
              if (op.active) {
                arr.push(Number(op.value));
              } else {
                if (
                  arr[arr.length - 1] === "*" ||
                  arr[arr.length - 1] === "/"
                ) {
                  arr.push(1);
                } else {
                  arr.push(0);
                }
              }
            } else {
              console.error("calc", calc[key]);
            }
          }
        }
      }
      field.value = eval(arr.join(" "));
      // console.log(field.id, arr)
      return eval(arr.join(" "));
    },
    variableValue(field) {
      if (!field.variableValue) {
        return;
      }
      const ifs = field.variableValue.if;
      const operator = {
        isBetween: (a, b, c) => a >= b[0] && a <= b[1],
        isGreater: (a, b) => a > b,
      };

      for (const index in ifs) {
        const a = this.findObjectById(ifs[index].condition[0], this.form).value;
        const opName = ifs[index].condition[1];
        const b = ifs[index].condition[2];

        const result = operator[opName](a, b);
        if (result) {
          field.value = ifs[index].value;
          return ifs[index].value;
        }
      }
      field.value = field.variableValue.else;
      return field.variableValue.else;
    },
    conditions(field, conditions) {
      if (!conditions) {
        return true;
      }
      const logics = conditions.logics || [];
      if (logics.length < 0) {
        return true;
      }

      const operator = {
        isEqual: (a, b) => a === b,
        isGreater: (a, b) => a > b,
        isLower: (a, b) => a < b,
        isEqualToCheckbox: function (a, b) {
          return JSON.stringify(a) === JSON.stringify(b);
        },
      };

      const form = this.form;

      const output = [];

      for (const logic in logics) {
        const arr = [];
        for (const logicAnd in logics[logic]) {
          const a = this.findObjectById(logics[logic][logicAnd].field, form)
            .value;
          const b = logics[logic][logicAnd].value;
          arr.push(operator[logics[logic][logicAnd].operator](a, b));
        }
        output.push(arr.every((a) => a));
      }

      field.active = output.some((a) => a);
      return output.some((a) => a);
    },
    inheritValue(field) {
      let arr = [];
      for (const key in field.inheritValue) {
        arr = [];
        for (const k in field.inheritValue[key].if) {
          arr.push(
            field.inheritValue[key].if[k].value ===
              this.findObjectById(field.inheritValue[key].if[k].id, this.form)
                .value
          );
        }
        const is = arr.every((a) => a);
        if (is) {
          field.value = field.inheritValue[key].value;
          return field.value;
        }
      }
    },
    i18n(obj, key) {
      if (obj.i18n) {
        if (obj.i18n[this.form.data.language]) {
          if (obj.i18n[this.form.data.language][key]) {
            return obj.i18n[this.form.data.language][key];
          }
        }
      }
      return obj[key];
    },
    i18nErrors(errors) {
      if (errors.includes("required")) {
        return [this.form.data.i18nErrors[this.form.data.language].required];
      }
      if (errors.includes("email")) {
        return [this.form.data.i18nErrors[this.form.data.language].email];
      }
      if (errors.includes("phone")) {
        return [this.form.data.i18nErrors[this.form.data.language].phone];
      }
      return [];
    },
  },
};
</script>

<style>
.field-group {
  border: 1px solid #ddd !important;
  padding: 16px;
}
</style>
