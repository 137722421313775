<template>
    <v-app id="app">

        <v-navigation-drawer permanent app>
            <div class="px-12 py-4">
                <v-img
                    :aspect-ratio="1"
                    src="./assets/logo-dlg.svg"
                ></v-img>
            </div>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">Administrare formulare</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav>
                <v-list-item link to="/evenimente">
                    <v-list-item-icon>
                        <v-icon>mdi-calendar</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Evenimente</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="/utilizatori">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Expozanti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <!-- <v-app-bar
            color="primary"
            dark
            flat
            app
            
        >
            <v-container v-if="form">
                <v-row>
                    <v-col align="center">
                        <v-responsive max-width="1024px" align="left">
                            <div class="d-flex flex-row align-center gap-16">
                                <div>
                                    <v-img
                                        width="40"
                                        :aspect-ratio="1"
                                        src="./assets/logo-white-dlg.svg"
                                    ></v-img>
                                </div>

                                <div>
                                    <h1 class="title font-weight-black">{{i18n(form.data, 'title')}}</h1>
                                </div>

                                <div class="spacer"></div>

                                <v-menu location="end">
                                    <template v-slot:activator="{ on, attrs }" location="end">
                                        <v-btn v-if="form.data.language === 'ro'" color="white" dark v-bind="attrs" v-on="on" text>
                                            <v-img
                                                class="rounded-circle mr-4"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/romanian-flag.svg"
                                            ></v-img>

                                            Romana
                                        </v-btn>

                                        <v-btn v-else color="white" dark v-bind="attrs" v-on="on" text>
                                            <v-img
                                                class="rounded-circle mr-4"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/english-flag.svg"
                                            ></v-img>

                                            English
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="changeToEnglish">
                                            <v-img
                                                class="rounded-circle"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/romanian-flag.svg"
                                            ></v-img>
                                            <v-list-item-title class="ml-4">
                                                Romana
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="changeToRomanian">
                                            <v-img
                                                class="rounded-circle"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/english-flag.svg"
                                            ></v-img>
                                            <v-list-item-title class="ml-4">
                                                English
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-btn color="white" dark text @click="exportToPDF">
                                    <v-img
                                        class="rounded-circle mr-4"
                                        width="24"
                                        :aspect-ratio="1"
                                        src="./assets/save-icon.svg"
                                    ></v-img>

                                    Descarca Deviz
                                </v-btn>

                                <v-menu location="end" v-if="false">
                                    <template v-slot:activator="{ on, attrs }" location="end">

                                        <v-btn color="white" dark v-bind="attrs" v-on="on" text>
                                            <v-img
                                                class="rounded-circle mr-4"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/save-icon.svg"
                                            ></v-img>

                                            Descarca
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="downloadJson">
                                            <v-img
                                                class="rounded-circle"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/json-icon.png"
                                            ></v-img>
                                            <v-list-item-title class="ml-4">
                                                JSON
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="exportToPDF" v-if="isSaved">
                                            <v-img
                                                class="rounded-circle"
                                                width="24"
                                                :aspect-ratio="1"
                                                src="./assets/json-icon.png"
                                            ></v-img>
                                            <v-list-item-title class="ml-4">
                                                Deviz
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-btn variant="tonal">Save</v-btn>
                                
                            </div>
                        </v-responsive>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar> -->



        

        <v-main v-if="form">
            <router-view :form="form"/>
        </v-main>
    </v-app>

</template>

<script>
    import axios from 'axios'
    import agrariaForm_f3 from './json/agraria-form-f3.json';
    import agrariaForm_f4 from './json/agraria-form-f4.json';
    import { jsPDF } from "jspdf";
    import autoTable from 'jspdf-autotable'

    export default {
        components: {
        },
        data: () => ({
            drawer: null,
            form: agrariaForm_f4,
            unfoldedObject: [],
            totalFaraTva: 0,
            totalTva: 0,
            total: 0,
            text: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            pasted: false,
            firstValue: '',
            isSaved: null,
            nrDeviz: 0,
            numeDeviz: null,
            items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard' },
          { title: 'Photos', icon: 'mdi-image' },
          { title: 'About', icon: 'mdi-help-box' },
        ],
        right: null,
        }, {
            drawer: null,
            form: agrariaForm_f3,
            unfoldedObject: [],
            totalFaraTva: 0,
            totalTva: 0,
            total: 0,
            text: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            pasted: false,
            firstValue: '',
            isSaved: null,
            nrDeviz: 0,
            numeDeviz: null,
            items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard' },
          { title: 'Photos', icon: 'mdi-image' },
          { title: 'About', icon: 'mdi-help-box' },
        ],
        right: null,
        }),
        created () {
            // this.getForm(1);
        },
        methods: {
            getForm (id) {
                const url = 'https://dlg2.dev-factory.ro/php/get-form-json.php';
                axios.get(url, { params: {id} }).then(response => {
                    this.form = response.data;
                });
            },
            findObjectById (id, object) {
                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        if (object[key] && typeof object[key] === "object") {
                            const result = this.findObjectById(id, object[key]);
                            if (result) {
                                return result;
                            }
                        }
                        if (object[key] && object[key].id === id) {
                            return object[key];
                        } 
                    }
                }
            },
            exportToPDF () {
            },
            changeToRomanian () {
                this.form.data.language = "en"
            },
            changeToEnglish () {
                this.form.data.language = "ro"
            },
            i18n (obj, key) {
                if (!obj) return;
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            },
            conditionsPDF (conditions) {
                if (!conditions) {return true;}
                const logics = conditions || [];
                if (logics.length < 0) {return true;}

                const operator = {
                    'isGreater': ( a, b ) => a > b,
                    'isEqual': ( a, b ) => a === b,
                    'isEqualToCheckbox': ( a, b ) => JSON.stringify(a) === JSON.stringify(b),
                    'isLang': ( a, b ) => this.form.data.language == a
                };

                const output = [];

                for (const logic in logics) {
                    const arr = [];
                    for (const logicAnd in logics[logic]) {
                        const a = logics[logic][logicAnd].field ? this.findObjectById(logics[logic][logicAnd].field, this.form).value : "";
                        const b = logics[logic][logicAnd].value;
                        arr.push(operator[ logics[logic][logicAnd].operator ](b, a));
                    }
                    output.push(arr.every(a => a));
                }
                return output.some(a => a);
            },
            inheritValue (field) {
                let arr = [];
                for (const key in field.inheritValue) {
                    arr = [];
                    for (const k in field.inheritValue[key].if) {
                        arr.push(field.inheritValue[key].if[k].value === this.findObjectById(field.inheritValue[key].if[k].id, this.form ).value)
                    }
                    const is = arr.every(a => a);
                    if (is) {
                        field.value = field.inheritValue[key].value;
                        return field.value;
                    }
                }
            },
            downloadJson () {
                let text = JSON.stringify(this.form);
                let filename = `${this.i18n(this.form.data, 'title')} - ${this.i18n(this.form.data,'eventName')}.json`;
                let element = document.createElement('a');
                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            formatDate () {
                let d     = new Date();
                let month = '' + (d.getMonth() + 1);
                let day   = '' + d.getDate();
                let year  = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                return [day, month, year].join('.');
            },
            calc (calc) {
                const arr = [];
                for (const key in calc) {
                    if (calc[key] === '*') {
                        arr.push('*');
                    } else if (calc[key] === '+') {
                        arr.push('+');
                    } else if (calc[key] === '-') {
                        arr.push('-');
                    } else if (calc[key] === '(') {
                        arr.push('(');
                    } else if (calc[key] === ')') {
                        arr.push(')');
                    } else if (calc[key] === 'if') {
                        arr.push('if');
                    } else if (calc[key] === 'else') {
                        arr.push('else');
                    } else if (calc[key] === '{') {
                        arr.push('{');
                    } else if (calc[key] === '}') {
                        arr.push('}');
                    } else if (calc[key] === '>') {
                        arr.push('>');
                    } else if (calc[key] === '<') {
                        arr.push('<');
                    } else if (calc[key] === '&&') {
                        arr.push('&&');
                    } else if (calc[key] === 'isLangRo') {
                        arr.push(this.form.data.language === 'ro');
                    } else {
                        if(typeof calc[key] == 'number') {
                            arr.push(calc[key]);
                        } else {
                            const op = this.findObjectById(calc[key], this.form );

                            if (typeof op !== 'undefined') {
                                if (op.active) {
                                    // console.log(Number(op.value))
                                    arr.push(Number(op.value));
                                } else {
                                    if (arr[arr.length -1] === "*" || arr[arr.length -1] === "/") {
                                        arr.push(1);
                                    } else {
                                        arr.push(0);
                                    }
                                }
                            } else {
                                console.error("calc", calc[key]);
                            }
                        }
                    }
                }

                return eval( arr.join(" "));
            },
            calcFromExcel (field, calc) {
                const arr = [];
                for (const key in calc) {
                    if (calc[key] === '*') {
                        arr.push('*');
                    } else if (calc[key] === '+') {
                        arr.push('+');
                    } else if (calc[key] === '-') {
                        arr.push('-');
                    } else if (calc[key] === '(') {
                        arr.push('(');
                    } else if (calc[key] === ')') {
                        arr.push(')');
                    } else if (calc[key] === 'if') {
                        arr.push('if');
                    } else if (calc[key] === 'else') {
                        arr.push('else');
                    } else if (calc[key] === '{') {
                        arr.push('{');
                    } else if (calc[key] === '}') {
                        arr.push('}');
                    } else if (calc[key] === '>') {
                        arr.push('>');
                    } else if (calc[key] === '&&') {
                        arr.push('&&');
                    } else if (calc[key] === '<') {
                        arr.push('<');
                    } else if (calc[key] === 'isLangRo') {
                        arr.push(this.form.data.language === 'ro');
                    } else {
                        if(typeof calc[key] == 'number') {
                            arr.push(calc[key]);
                        } else {
                            const op = this.findObjectById(calc[key], this.form );
                            if (typeof op !== 'undefined') {
                                if (op.active) {
                                    arr.push(Number(op.value));
                                } else {
                                    if (arr[arr.length -1] === "*" || arr[arr.length -1] === "/") {
                                        arr.push(1);
                                    } else {
                                        arr.push(0);
                                    }
                                }
                            } else {
                                console.error("calc", calc[key]);
                            }
                        }
                    }
                }
                // field.value = eval( arr.join(" ") );
                return eval( arr.join(" "));
            },
        }
    }

</script>

<style>
    .gap-16 {
        gap: 16px;
    }

    #customers,
    #desfasurator {
        border-collapse: collapse;
        font-size: 14px;
        font-family: sans-serif;
    }

    #customers {
        font-size: 14px;
    }

    #customers td,
    #desfasurator td,
    #customers th,
    #desfasurator th {
        border: 1px solid black;
        padding: 4px;
    }

    .standard,
    .style-1 {
        width: 842px;
        margin-bottom: 16px;
    }


    .standard th,
    #desfasurator th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
        font-weight: bold;
    }

    .standard th {
        background-color: #FFFFFF;
        color: black;
    }

    .v-radio {
        align-items: start !important;
    }

    .standard,
    .style-1 {
        border-collapse: collapse;
        font-size: 16px;
        font-family: sans-serif;
    }

    .standard {
        font-size: 14px;
    }

    .standard td,
    .style-1 td,
    .standard th,
    .style-1 th {
        border: 1px solid black;
        padding: 8px;
    }


    .standard th,
    .style-1 th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
        font-weight: bold;
    }

    .standard th {
        background-color: #FFFFFF;
        color: black;
    }
</style>