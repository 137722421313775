<template>
    <v-combobox
            v-model="field.value"
            :items="field.items"
            :label="i18n(field, 'label')"
            multiple
            filled
            dense
            required
            :hint="i18n(field, 'hint')"
            persistent-hint
            small-chips
        ></v-combobox>
</template>

<script>

    export default {
        name: 'field-combobox',
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
